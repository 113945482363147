// eslint-disable-next-line no-unused-vars
import React from "react";
import { List } from "immutable";
import { DATE, DIALOG, RENDER } from "../../components/orders-core/MUITable";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import { BOLD } from "../../components/ui-core/Text";
import PaymentTypeAvatarAndInfo from "../../components/orders-core/PaymentTypeAvatarAndInfo";
import _ from "lodash";
import FlexBox, { ALIGN_CENTER } from "../../components/ui-core/FlexBox";
import { CallMade } from "@material-ui/icons";
import OrderListServiceType from "../../components/orders-core/OrderListServiceType";
import AvatarWithName2 from "../../components/avatars/AvatarWithName2";
import Fragile from "../../assets/fragile.png";

export const ID = {
  ind: 1,
  slug: "id",
  label: "id",
  keyName: "id",
  name: "Id",
  type: DIALOG,
};
export const BARCODE = {
  ind: 1,
  slug: "barcode",
  label: "barcode",
  keyName: "barcode",
  name: "Barcode",
  type: DIALOG,
  align: "center",
};

export const CUSTOMER = {
  ind: 1,
  slug: "customer",
  label: "sender_name",
  keyName: "customer.name",
  name: "Customer",
  align: "center",
  longString: true,
};

export const WAREHOUSE = {
  ind: 2,
  slug: "warehouse",
  label: "warehouse",
  keyName: "warehouse",
  name: "Warehouses",
  type: RENDER,
  render: row => (
    <MultiLineCell
      firstLine={
        <FlexBox align={ALIGN_CENTER}>
          <CallMade
            fontSize="small"
            style={{
              color: "#A8ADAF",
              fontSize: "1rem",
              transform: "rotate(90deg)",
            }}
          />
          <span style={{ color: "#A8ADAF" }}>
            {_.get(row, "from_warehouse.name", "-")}
          </span>
        </FlexBox>
      }
      secondLine={
        <FlexBox align={ALIGN_CENTER}>
          <CallMade
            fontSize="small"
            style={{
              color: "#21C004",
              transform: "rotate(135deg)",
              fontSize: "1rem",
            }}
          />
          <span style={{ color: "#263238" }}>
            {_.get(row, "warehouse.name", "-")}
          </span>
        </FlexBox>
      }
      thirdLine={
        <FlexBox align={ALIGN_CENTER}>
          <CallMade
            fontSize="small"
            style={{
              color: "#A8ADAF",
              fontSize: "1rem",
            }}
          />
          <span style={{ color: "#A8ADAF" }}>
            {_.get(row, "to_warehouse.name", "-")}
          </span>
        </FlexBox>
      }
    />
  ),
};

export const SUPPLIER = {
  ind: 3,
  slug: "supplier_name",
  label: "supplier_name",
  keyName: "supplier.name",
  name: "Supplier Name",
};
export const DROPOFF_ADDRESS = {
  ind: 4,
  slug: "dropoff_address",
  label: "dropoff_address",
  keyName: "locations.1.address",
  name: "DropOff Address",
  align: "center",
  longString: true,
};
export const SERVICE = {
  ind: 5,
  slug: "service",
  label: "shipment_type",
  keyName: "package.delivery_label",
  name: "Service",
  type: RENDER,
  render: (row, getLocalisationMessage) =>
    _.get(row, "notification.enabled") ? (
      <OrderListServiceType order={row} />
    ) : _.get(row, "head_order_id") ? (
      <AvatarWithName2
        name={getLocalisationMessage("notification")}
        isNotification={true}
      >
        <MultiLineCell
          firstLine={
            <strong style={{ textTransform: "capitalize" }}>
              {getLocalisationMessage("notification")}
            </strong>
          }
        />
      </AvatarWithName2>
    ) : (
      <AvatarWithName2
        name={_.get(row, "package.delivery_label", "-")}
        imagePath={_.get(row, "package.courier_type_icon")}
      >
        <MultiLineCell
          firstLine={
            <strong>{_.get(row, "package.delivery_label", "-")}</strong>
          }
          secondLine={_.get(row, "package.menu.name")}
        />
      </AvatarWithName2>
    ),
};
export const FRAGILE = {
  ind: 5,
  slug: "fragile",
  label: "fragile",
  keyName: "is_fragile",
  name: "fragile",
  align: "center",
  type: RENDER,
  render: row =>
    _.get(row, "is_fragile") && (
      <FlexBox
        align={ALIGN_CENTER}
        justify="center"
        style={{ marginRight: 35 }}
      >
        <img src={Fragile} style={{ width: 40, height: 40 }} alt="fragile" />
      </FlexBox>
    ),
};
export const TIMESLOT = {
  ind: 6,
  slug: "timeslot",
  label: "timeslot",
  keyName: "timeslot",
  name: "Timeslot (Pickup | Dropoff)",
};
export const BOX_COUNT = {
  ind: 7,
  slug: "piece_count",
  label: "piece_count",
  keyName: "piece_count",
  name: "Box Count",
};
export const ATTEMPTS = {
  ind: 8,
  slug: "attempts_count",
  label: "attempts_count",
  keyName: "attempts_count",
  name: "Attempts",
};
export const CREATED_DATE = {
  ind: 9,
  slug: "created_date",
  label: "created_date",
  keyName: "created_date",
  name: "Created Time",
  type: DATE,
  isSortable: true,
  align: "center",
};
export const PICKUP_DATE = {
  ind: 10,
  slug: "pickup_time",
  label: "pickup_time",
  keyName: "pickup_time",
  name: "Pickup Time",
  type: DATE,
};
export const DEADLINE_TIME = {
  ind: 11,
  slug: "deadline_time",
  label: "deadline_time",
  keyName: "deadline_time",
  name: "Est. Delivery",
  type: DATE,
};
export const PROMISE_DATE = {
  ind: 12,
  slug: "merchant_promise_date",
  label: "merchant_promise_date",
  keyName: "merchant_promise_date",
  name: "Promise Date",
  type: DATE,
};
export const PICKED_UP_BY = {
  ind: 13,
  slug: "pickup_supplier_name",
  label: "pickup_supplier_name",
  keyName: "pickup_supplier_name",
  name: "Picked Up By",
};
export const PICKUP_TOTAL_COLLECTABLE = {
  ind: 14,
  slug: "pickup_collectible",
  label: "pickup_collectible",
  keyName: "pickup_collectible",
  name: "Pickup Total Collectible",
};
export const DELIVERED_BY = {
  ind: 15,
  slug: "delivery_supplier_name",
  label: "delivery_supplier_name",
  keyName: "delivery_supplier_name",
  name: "Delivered By",
};
export const DROPOFF_TOTAL_COLLECTABLE = {
  ind: 16,
  slug: "dropoff_collectible",
  label: "dropoff_collectible",
  keyName: "dropoff_collectible",
  name: "Dropoff Total Collectible",
};
export const STATUS = {
  ind: 17,
  slug: "status",
  label: "status",
  keyName: "status",
  name: "Status",
  withTranslation: true,
  align: "center",
};
export const COD_CHARGE = {
  ind: 18,
  slug: "cod_charge",
  label: "cod_charge",
  keyName: "cod_charge",
  name: "COD",
};
export const COD_STATUS = {
  ind: 19,
  slug: "cod_status",
  label: "cod_status",
  keyName: "cod_status",
  name: "COD Status",
};
export const AMOUNT = {
  ind: 20,
  align: "center",
  slug: "amount",
  label: "total",
  keyName: "amount",
  name: "Total",
  withRender: true,
  render: row => (
    <PaymentTypeAvatarAndInfo paymentType={_.get(row, "payment_type")}>
      <MultiLineCell
        style={{ paddingRight: "10px" }}
        firstLine={<PriceWrapper price={_.get(row, "amount")} type={[BOLD]} />}
      />
    </PaymentTypeAvatarAndInfo>
  ),
};
export const SHIPPING_TYPE = {
  ind: 21,
  slug: "shipping_type",
  label: "shipping_type",
  keyName: "shipping_type",
  name: "Shipping Type",
};
export const PICKUP_ADDRESS = {
  ind: 22,
  slug: "pickup_address",
  label: "pickup_address",
  keyName: "pickup_address",
  name: "Pickup Address",
};
export const WEIGHT = {
  ind: 23,
  slug: "weight",
  label: "weight",
  keyName: "weight",
  name: "Weight",
};
export const BATCH = {
  ind: 24,
  slug: "batch",
  label: "batch",
  keyName: "batch",
  name: "Batch",
};
export const PAYMENT_TYPE = {
  ind: 25,
  slug: "payment_type",
  label: "payment_type",
  keyName: "payment_type",
  name: "payment_type",
  withTranslation: true,
  align: "center",
};
export const defaultColumns = [
  BARCODE,
  SERVICE,
  DROPOFF_ADDRESS,
  CREATED_DATE,
  STATUS,
];
export default List([
  BARCODE,
  SERVICE,
  FRAGILE,
  CUSTOMER,
  WAREHOUSE,
  DROPOFF_ADDRESS,
  CREATED_DATE,
  STATUS,
  PAYMENT_TYPE,
  AMOUNT,
  ATTEMPTS,
  PICKUP_DATE,
  DEADLINE_TIME,
  PROMISE_DATE,
  PICKED_UP_BY,
  PICKUP_TOTAL_COLLECTABLE,
  DELIVERED_BY,
  DROPOFF_TOTAL_COLLECTABLE,
  COD_CHARGE,
  COD_STATUS,
  SHIPPING_TYPE,
  PICKUP_ADDRESS,
  WEIGHT,
  BATCH,
  SUPPLIER,
  BOX_COUNT,
  ID,
  TIMESLOT,
]);
